import React from 'react';
import { Modal, ListGroup } from 'react-bootstrap';

const AttachmentsModal = ({ show, onHide, attachments, onAttachmentClick }) => {
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header style={{ backgroundColor: '#007bff' }} closeButton>
        <Modal.Title>Attachments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {attachments.map((attachment, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
              <span>Attachment {index + 1}</span>
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => onAttachmentClick(attachment)}
              >
                View Attachment
              </span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default AttachmentsModal;
