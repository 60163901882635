import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsOfUse = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 style={{ textAlign: 'center' }} className="my-4">Terms of Use</h1>
          <p><strong>Last Updated: 9<span style={{ fontSize: '0.75rem', verticalAlign: 'super' }}>th</span> September, 2024</strong></p>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using Phishroko.com, a website operated by Capank Associates Private Limited (“we,” “us,” “our”), you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please do not use our website or services.
          </p>

          <h2>2. Use of Website</h2>
          <p>
            You agree to use our website only for lawful purposes and in a way that does not infringe the rights of others or restrict or inhibit their use of the website. Prohibited activities include, but are not limited to:
          </p>
          <ul>
            <li>Engaging in illegal activities.</li>
            <li>Distributing malware or other harmful code.</li>
            <li>Attempting to gain unauthorized access to our systems or services.</li>
          </ul>

          <h2>3. Intellectual Property</h2>
          <p>
            All content on our website, including text, graphics, logos, images, and software, is the property of Capank Associates Private Limited or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from our website without prior written permission from us.
          </p>

          <h2>4. User Accounts</h2>
          <p>
            When creating an account with us, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password and for any activity that occurs under your account.
          </p>

          <h2>5. Limitation of Liability</h2>
          <p>
            We make no warranties or representations about the accuracy or completeness of the content provided on our website. To the fullest extent permitted by law, Capank Associates Private Limited disclaims any and all liability for any damages arising from your use of our website or services.
          </p>

          <h2>6. Third-Party Links</h2>
          <p>
            Our website may contain links to third-party websites. These links are provided for your convenience only. We have no control over the content or practices of these third-party sites and accept no responsibility for them. Your use of linked websites is at your own risk.
          </p>

          <h2>7. Changes to These Terms</h2>
          <p>
            We reserve the right to modify or update these Terms of Use at any time. Your continued use of the website after any changes to these terms will constitute your acceptance of such changes. Please review this page periodically to ensure you are aware of any updates.
          </p>

          <h2>8. Termination</h2>
          <p>
            We reserve the right to suspend or terminate your access to our website or services at any time, without notice, for any violation of these Terms of Use or for any other reason at our discretion.
          </p>

          <h2>9. Governing Law</h2>
          <p>
            These Terms of Use are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts in Ghaziabad, Uttar Pradesh, India.
          </p>

          <h2>10. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms of Use, please contact us at:
          </p>
          <p><strong>Email:</strong> support@phishroko.com</p>
          <p><strong>Company Name:</strong> Capank Associates Private Limited</p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfUse;
