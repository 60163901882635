import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ShippingAndDelivery = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 style={{ textAlign: 'center' }} className="my-4">Shipping and Delivery Policy</h1>
          <p><strong>Last Updated: 9<span style={{ fontSize: '0.75rem', verticalAlign: 'super' }}>th</span> September, 2024</strong></p>

          <h2>1. Introduction</h2>
          <p>
            At Phishroko.com, operated by Capank Associates Private Limited, we provide SaaS solutions. This Shipping and Delivery Policy outlines how our services are delivered to customers.
          </p>

          <h2>2. Service Delivery</h2>
          <p>
            Since we provide digital services, there are no physical products to be shipped. Upon successful purchase of a subscription or service, access to our services is provided immediately after payment confirmation.
          </p>
          <p>
            You will receive an email confirmation with details about your subscription or service activation. The service is accessible through your online account on Phishroko.com, using the login credentials created during signup.
          </p>

          <h2>3. Account Access</h2>
          <p>
            After purchasing our SaaS products, users will be able to log into their account to access all the features associated with their subscription. In the event of any delays in activation, users should contact our support team at <strong>support@phishroko.com</strong> for assistance.
          </p>

          <h2>4. Service Availability</h2>
          <p>
            Our services are available 24/7, subject to maintenance periods and unexpected outages. We strive to maintain high uptime and will notify customers of any planned downtime via email or on our website.
          </p>

          <h2>5. International Availability</h2>
          <p>
            Our SaaS services are available to customers worldwide. However, certain restrictions or limitations may apply depending on local laws or regulations.
          </p>

          <h2>6. Support and Assistance</h2>
          <p>
            We offer customer support for any issues related to service access or functionality. If you experience any issues, feel free to contact our support team at <strong>support@phishroko.com</strong>. Our team is available to help resolve any problems promptly.
          </p>

          <h2>7. Changes to this Policy</h2>
          <p>
            We may update or modify this Shipping and Delivery Policy from time to time to reflect changes in our services or policies. Any updates will be posted on this page, and we encourage users to review it periodically.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions or concerns regarding this Shipping and Delivery Policy, please contact us at:
          </p>
          <p><strong>Email:</strong> support@phishroko.com</p>
          <p><strong>Company Name:</strong> Capank Associates Private Limited</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ShippingAndDelivery;
