import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const ForgotPasswordModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseMessageColor, setResponseMessageColor] = useState(''); // Added state for message color
  const [loading, setLoading] = useState(false); // For spinner
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setResponseMessage('');
    setResponseMessageColor(''); // Reset message color
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Account/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setVerificationCode(data.verificationCode); // Save verification code
        setResponseMessage('OTP has been sent to your email address.');
        setResponseMessageColor('green'); // Set color to green for success
        setLoading(false);
        setOtpModalShow(true);
      } else {
        const errorText = await response.text();
        if (errorText === 'User does not exists') {
          setResponseMessage('User does not exist.');
        } else {
          setResponseMessage('Failed to send password reset email.');
        }
        setResponseMessageColor('red'); // Set color to red for error messages
        setLoading(false);
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setResponseMessage('An error occurred while sending the reset email.');
      setResponseMessageColor('red'); // Set color to red for error messages
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();

    if (otp.length !== 6) {
      setResponseMessage('OTP must be 6 digits long.');
      setResponseMessageColor('red'); // Set color to red for error messages
      return;
    }

    if (password !== confirmPassword) {
      setResponseMessage('Passwords do not match.');
      setResponseMessageColor('red'); // Set color to red for error messages
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Account/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          otp,
          validationCode: verificationCode,
          newPassword: password
        }),
      });

      if (response.ok) {
        setSuccessModalShow(true);
        setOtpModalShow(false);
      } else {
        const errorData = await response.text();
        setResponseMessage(errorData);
        setResponseMessageColor('red'); // Set color to red for error messages
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setResponseMessage('An error occurred while resetting password.');
      setResponseMessageColor('red'); // Set color to red for error messages
    }
  };

  const handleOtpModalClose = () => {
    setOtpModalShow(false);
    setEmail(''); // Clear email field
    setOtp(''); // Clear OTP field
    setPassword(''); // Clear password field
    setConfirmPassword(''); // Clear confirm password field
    setResponseMessage(''); // Clear response message
    setResponseMessageColor(''); // Clear message color
  };

  const handleSuccessModalClose = () => {
    setSuccessModalShow(false);
    handleClose(); // Close the Forgot Password modal
  };

  return (
    <>
      {/* Forgot Password Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Modal.Body style={{ position: 'relative' }}>
          <Button
            variant="close"
            onClick={() => {
              handleClose();
              setEmail('');
              setResponseMessage('');
              setResponseMessageColor('');
            }}
            style={{ position: 'absolute', top: '10px', right: '10px' }}
          >
            &times;
          </Button>
          <div className="text-center mb-4">
            <h4>Forgot Password</h4>
          </div>
          <Form onSubmit={handleResetPassword}>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            {responseMessage && (
              <p className="text-center" style={{ color: responseMessageColor }}>
                {responseMessage}
              </p>
            )}
            <div className="text-center mb-3">
              <Button 
                type="submit" 
                variant="primary"
                disabled={loading}
              >
                {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Reset Password'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* OTP Modal */}
      <Modal
        show={otpModalShow}
        onHide={handleOtpModalClose}
        centered
        backdrop="static"
        keyboard={false}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Modal.Body style={{ position: 'relative' }}>
          <Button
            variant="close"
            onClick={handleOtpModalClose}
            style={{ position: 'absolute', top: '10px', right: '10px' }}
          >
            &times;
          </Button>
          <div className="text-center mb-4">
            <h4>Enter OTP and New Password</h4>
            {/* <p>OTP has been sent to your email address.</p> */}
          </div>
          <Form onSubmit={handleOtpSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter 6-digit OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Control
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button
                variant="link"
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
              >
                {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </Button>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Control
                type={confirmPasswordVisible ? 'text' : 'password'}
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <Button
                variant="link"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
              >
                {confirmPasswordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </Button>
            </Form.Group>
            {responseMessage && (
              <p className="text-center" style={{ color: responseMessageColor }}>
                {responseMessage}
              </p>
            )}
            <div className="text-center mb-3">
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Success Modal */}
      <Modal
        show={successModalShow}
        onHide={handleSuccessModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="text-center">
          <h4>Password Reset Successfully</h4>
          <Button variant="primary" onClick={handleSuccessModalClose}>
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
