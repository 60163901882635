import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import { FaExclamationCircle, FaDollarSign, FaUsers, FaComments, FaLink } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Dashboard = () => {
  const username = JSON.parse(localStorage.getItem('userData'))?.username || 'Guest';

  const dashboardContainerStyle = {
    display: 'flex',
    minHeight: '100vh',
    paddingTop: '20px',
    backgroundImage: `url('/path/to/your/image.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
  };

  const sidebarStyle = {
    position: 'fixed',
    top: '60px',
    width: '20%',
    height: 'calc(100vh - 80px)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: '#f8f9fa',
    overflowY: 'auto',
  };

  const contentStyle = {
    marginLeft: '20%',
    padding: '0rem',
    width: '80%',
  };

  const cardBaseStyle = {
    height: '75px',
    borderRadius: '10px',
    color: '#fff',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align items to the start (left side)
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', // Initial shadow for depth
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    transform: 'translateY(0)', // Initial position
  };

  const hoverEffect = {
    transform: 'translateY(-5px)', // Move up on hover
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.25)', // Deeper shadow on hover for more depth
  };

  const normalEffect = {
    transform: 'translateY(0)', // Reset to normal position
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', // Reset shadow
  };

  const solidColors = [
    { backgroundColor: '#FF9900' }, // Complaints card
    { backgroundColor: '#34a853' }, // Community card
    { backgroundColor: '#6599FF' }, // Subscription card
    { backgroundColor: '#ff7043' }, // Chat with Us card
    { backgroundColor: '#097054' }, // Report URL card
  ];

  const renderCard = (to, style, icon, text) => (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Card
        style={{
          ...cardBaseStyle,
          ...style,
        }}
        onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverEffect)}
        onMouseLeave={(e) => Object.assign(e.currentTarget.style, normalEffect)}
      >
        <Row className="h-100 align-items-center justify-content-center">
          <Col xs={2} className="text-center">
            <span style={{ fontSize: '1.5rem' }}>
              {icon}
            </span>
          </Col>
          <Col xs={10} className="text-center">
            <span style={{ fontSize: '1.25rem' }}>
              {text}
            </span>
          </Col>
        </Row>
      </Card>
    </Link>
  );

  return (
    <div style={dashboardContainerStyle}>
      <div style={sidebarStyle}>
        {renderCard('/dashboard/complaints', solidColors[0], <FaExclamationCircle />, 'Your Complaints')}
        {renderCard('/dashboard/subscription', solidColors[2], <FaDollarSign />, 'Subscription')}
        {renderCard('/dashboard/community', solidColors[1], <FaUsers />, 'Community')}
        {renderCard('/dashboard/chat', solidColors[3], <FaComments />, 'Chat with Us')}
        {renderCard('/dashboard/reportUrl', solidColors[4], <FaLink />, 'URL Service')}
      </div>
      <div style={contentStyle}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;