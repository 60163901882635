import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navigation.css";
import LoggedInNavbar from "./LoggedInNavbar";
import NotLoggedInNavbar from "./NotLoggedInNavbar";

const Navigation = () => {
  const [navbarStyle, setNavbarStyle] = useState("navbar-transparent");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInitials, setUserInitials] = useState("");
  const [fullName, setFullName] = useState("");
  const [showModal, setShowModal] = useState(null);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
      const response = await fetch(
       `${process.env.REACT_APP_API_BASE_URL}/api/User/get-user-details?id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUserDetails(data);
      } else {
        console.error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleProfileClick = () => {
    fetchUserDetails();
    // This method needs to be updated to manage profile modal visibility
  };

  const handleOpenLogin = () => setShowModal("login");
  const handleOpenSignup = () => setShowModal("signup");
  const handleClose = () => setShowModal(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("userData"));

    if (token && user) {
      setIsLoggedIn(true);
      setFullName(user.fullName);
      const initials = user.fullName.charAt(0).toUpperCase();
      setUserInitials(initials);
      setNavbarStyle("navbar-logged-in");
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLoginSuccess = () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("userData"));

    if (token && user) {
      setIsLoggedIn(true);
      setFullName(user.fullName);
      const initials = user.fullName.charAt(0).toUpperCase();
      setUserInitials(initials);
      setNavbarStyle("navbar-logged-in");
      handleClose();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    setIsLoggedIn(false);
    setUserInitials("");
    setFullName("");
    setNavbarStyle("navbar-transparent");
    navigate("/");
  };

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${navbarStyle}`}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src="../assets/images/phishroko_cropped.png"
            alt="Logo"
            className="navbar-logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            {/* Add navigation links here */}
          </ul>
          {isLoggedIn ? (
            <LoggedInNavbar
              userInitials={userInitials}
              fullName={fullName}
              handleProfileClick={handleProfileClick}
              handleLogout={handleLogout}
            />
          ) : (
            <NotLoggedInNavbar
              handleOpenLogin={handleOpenLogin}
              handleOpenSignup={handleOpenSignup}
              showModal={showModal}
              handleClose={handleClose}
              handleLoginSuccess={handleLoginSuccess}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
