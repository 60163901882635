import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, ProgressBar, Alert, Spinner } from 'react-bootstrap';
import AttachmentsModal from './AttachmentsModal'; // Update path if necessary

const ViewTicketModal = ({ show, onHide, selectedTicket, fetchTickets }) => {
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(selectedTicket ? selectedTicket.desc : '');
  const [files, setFiles] = useState([]);
  const [attachmentFields, setAttachmentFields] = useState([{ id: 1 }]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // New state to handle saving status
  const uploadAbortController = useRef(new AbortController());

  const maxAttachments = 5;
  useEffect(() => {
    if (show) {
      // Reset state when modal opens
      const existingAttachments = selectedTicket ? selectedTicket.attachment.split(';') : [];
      setAttachments(existingAttachments);
      setEditedDescription(selectedTicket ? selectedTicket.desc : '');
      setFiles([]);
      
      // Set attachment fields based on existing attachments
      if (existingAttachments.length < 5) {
        setAttachmentFields([{ id: existingAttachments.length + 1 }]);
      } else {
        setAttachmentFields([]); // No fields if 5 or more attachments already exist
      }
      
      setUploadProgress({});
      setResponseMessage('');
    }
  }, [show, selectedTicket]);

  const handleFileChange = (event, fieldId) => {
    const file = event.target.files[0];
    if (file && file.size <= 524288000) { // 500 MB limit
      const updatedFiles = files.filter(f => f.id !== fieldId);
      setFiles([...updatedFiles, { id: fieldId, file }]);
    } else {
      alert("File size exceeds the limit of 500 MB.");
    }
  };

  const handleAddAttachment = () => {
    if (attachmentFields.length + attachments.length < 5) {
      setAttachmentFields([...attachmentFields, { id: attachmentFields.length + attachments.length + 1 }]);
    } else {
      alert(`You can only have up to ${maxAttachments} attachments in total.`);
    }
  };

  const uploadFile = (file, id) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
      const formData = new FormData();
      formData.append("file", file);

      xhr.open(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/api/files/upload?userId=${userId}`,
        true
      );
      const controller = uploadAbortController.current;
      xhr.signal = controller.signal;

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          setUploadProgress(prev => ({ ...prev, [id]: percentComplete }));
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          resolve(response.fileUrl);
        } else {
          reject("Failed to upload file");
        }
      };

      xhr.onerror = () => reject("Error during file upload");

      xhr.send(formData);
    });
  };

  const handleCreateTicketSubmit = async () => {
    setIsEditing(true); // Set to true to allow saving
    setIsSaving(true); // Set to true to show spinner

    try {
      const fileUrls = [];
      for (const { file, id } of files) {
        if (file) {
          const fileUrl = await uploadFile(file, id);
          fileUrls.push(fileUrl);
        }
      }

      const token = localStorage.getItem("token");

      // Concatenate new URLs with existing attachment URLs
      const newAttachments = fileUrls.length > 0 ? fileUrls.join(';') : '';
      const allAttachments = newAttachments 
        ? `${selectedTicket.attachment};${newAttachments}` 
        : selectedTicket.attachment;

      const ticketData = { 
        id: selectedTicket.id, 
        title: selectedTicket.title, 
        desc: editedDescription,
        entityType: selectedTicket.entityType,
        resolution: selectedTicket.resolution || '',
        attachment: allAttachments, // Use the concatenated attachment URLs
        status: selectedTicket.status,
        assignTo: selectedTicket.assignedTo, // Map to assignTo
        assignFor: selectedTicket.createdFor // Map to assignFor
      };

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/Ticket/update-ticket`; // Update URL for editing
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(ticketData),
      });

      console.log('Response:', response); // Log the response object

      if (response.ok) { // Check if the response is ok (status 200-299)
        setResponseMessage('Ticket updated successfully!');
        // await fetchTickets(); // Fetch updated tickets
      } else {
        setResponseMessage("Failed to update ticket. Please try again.");
      }
    } catch (error) {
      console.error("Ticket update failed:", error);
      setResponseMessage("Failed to update ticket. Please try again.");
    } finally {
      setIsSaving(false); // Set to false to hide spinner
      setIsEditing(false);
      setUploadProgress({});
      setFiles([]);
      setAttachmentFields([{ id: 1 }]);
      setShowResponseModal(true);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    setFiles([]);
    setAttachmentFields([{ id: 1 }]);
    setUploadProgress({});
    setResponseMessage('');
    setShowResponseModal(false);
    onHide();
  };

  const handleAttachmentClick = () => {
    if (selectedTicket && selectedTicket.attachment) {
      setAttachments(selectedTicket.attachment.split(';'));
      setShowAttachmentsModal(true);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return { text: "Open", color: "blue" };
      case 1:
        return { text: "Assigned", color: "orange" };
      case 2:
        return { text: "Resolved", color: "green" };
      case 3:
        return { text: "Closed", color: "grey" };
      default:
        return { text: "Unknown", color: "black" };
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered  
      backdrop="static" // Prevent closing on clicking outside
        keyboard={false} // Prevent closing on pressing escape key
        >
        <Modal.Header style={{ backgroundColor: '#007bff' }} closeButton>
          <Modal.Title>Ticket Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTicket && (
            <>
              <h4>{`ID: ${selectedTicket.ticketId}`}</h4>
              <p>
                <strong>Title:</strong>
                <span
                  style={{
                    display: "block",
                    border: "1px solid #ccc",
                    padding: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#f8f9fa",
                    marginTop: "5px",
                    maxHeight: "100px",
                    overflowY: "auto",
                    wordWrap: "break-word",
                  }}
                >
                  {selectedTicket.title}
                </span>
              </p>
              <p>
                <strong>Description:</strong>
                <Form.Control
                  as="textarea"
                  rows={3}
                  readOnly={!isEditing}
                  value={isEditing ? editedDescription : selectedTicket.desc}
                  onChange={(e) => setEditedDescription(e.target.value)}
                />
              </p>
              {isEditing && (
                <>
                  {attachmentFields.map(({ id }) => (
                    <Form.Group key={id} controlId={`formFile${id}`}>
                      <Form.Label>Attachment {id + attachments.length}</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={(e) => handleFileChange(e, id)}
                      />
                      {uploadProgress[id] !== undefined && (
                        <ProgressBar
                          animated
                          now={uploadProgress[id]}
                          label={`${uploadProgress[id]}%`}
                          style={{ marginTop: '10px' }}
                        />
                      )}
                    </Form.Group>
                  ))}
                  {attachmentFields.length + attachments.length < maxAttachments && (
                    <Button
                      variant="link"
                      onClick={handleAddAttachment}
                      style={{ display: 'block', margin: '10px 0' }}
                    >
                      Add more attachments
                    </Button>
                  )}
                </>
              )}
              {selectedTicket.attachment && !isEditing && (
                <p>
                  <strong>Attachment:</strong>{" "}
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={handleAttachmentClick}
                  >
                    View Attachments
                  </span>
                </p>
              )}
              <p>
                <strong>Status:</strong>{" "}
                <span
                  style={{
                    color: getStatusText(selectedTicket.status).color,
                    fontWeight: "bold",
                  }}
                >
                  {getStatusText(selectedTicket.status).text}
                </span>
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(selectedTicket.createdAt).toLocaleString()}
              </p>
              <p>
                <strong>Last Updated At:</strong>{" "}
                {new Date(selectedTicket.updatedAt).toLocaleString()}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div style={{ marginLeft: 'auto' }}>
            {!isEditing ? (
              <Button variant="primary" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={handleCreateTicketSubmit}
                disabled={uploadProgress && Object.values(uploadProgress).some(p => p < 100) || isSaving} // Disable if uploading or saving
              >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '5px' }}
                    />
                    Saving...
                  </>
                ) : (
                  'Save'
                )}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <AttachmentsModal
        show={showAttachmentsModal}
        onHide={() => setShowAttachmentsModal(false)}
        attachments={attachments}
        onAttachmentClick={(attachment) => {
          window.open(attachment, "_blank"); // For now, just open the attachment in a new tab
        }}
      />

      {/* Response Modal */}
      <Modal show={showResponseModal} onHide={handleClose} centered size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={responseMessage.includes('successfully') ? "success" : "danger"}>
            {responseMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewTicketModal;
