import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import Homepage from './components/HomePage';
import LoginPage from './components/authPage/LoginModal';
import Dashboard from './components/functionPages/Dashboard'; 
import Complaints from './components/functionPages/complaints/Complaints';
import SubscriptionPage from './components/functionPages/SubscriptionPage';
import CommunityPage from './components/functionPages/communityPage';
import Chat from './components/functionPages/chat';
// import chat from './components/functionPages/chat';
import ReportUrl from './components/functionPages/reportUrl';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReturnRefundPolicy from './components/ReturnRefundPolicy';
import TermsOfUse from './components/TermsOfUse';
import ShippingAndDelivery from './components/ShippingAndDelivery';
import ChatDummy from './components/functionPages/chatDummy';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userData = localStorage.getItem('userData');
        const currentPath = location.pathname;

        // Exclude paths from redirection
        const excludedPaths = ['/privacypolicy', '/return-refund-policy', '/terms-of-use','/ShippingAndDelivery'];

        if (token && userData) {
            // Redirect if user is on the homepage or login page
            if (currentPath === '/' || currentPath === '/login') {
                navigate('/dashboard/complaints');
            }
        } else {
            // Redirect to homepage if not logged in, unless on an excluded path
            if (!excludedPaths.includes(currentPath)) {
                navigate('/');
            }
        }
    }, [navigate, location.pathname]);

    return (
        <>
            <Navigation />
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/terms-of-use' element={<TermsOfUse />} />
                <Route path='/return-refund-policy' element={<ReturnRefundPolicy />} />
                <Route path='/ShippingAndDelivery' element={<ShippingAndDelivery />} />
                
                <Route path="/dashboard" element={<Dashboard />}>
                    <Route path="complaints" element={<Complaints />} />
                    <Route path="subscription" element={<SubscriptionPage />} />
                    <Route path="community" element={<CommunityPage />} />
                    <Route path="chat" element={<ChatDummy />} />
                    <Route path="reportUrl" element={<ReportUrl />} />
                </Route>
                {/* Add other routes here */}
            </Routes>
        </>
    );
};

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
