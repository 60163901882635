import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ReturnRefundPolicy = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 style={{ textAlign: 'center' }} className="my-4">Return and Refund Policy</h1>
          <p><strong>Last Updated: 9<span style={{ fontSize: '0.75rem', verticalAlign: 'super' }}>th</span> September, 2024</strong></p>

          <h2>1. 30-Day Return Policy</h2>
          <p>
            At Phishroko.com, a division of Capank Associates Private Limited, we offer a 30-day return policy for all  services purchased through our website. If you are not satisfied with your subscription, you may request a return within 30 days of the purchase date. Please note that the subscription must be canceled within this period to be eligible for a return.
          </p>

          <h2>2. Refund Processing Time</h2>
          <p>
            Once we receive your cancellation request, we will process your refund within 7 working days. The refund will be issued to the original payment method used at the time of purchase. Please allow additional time for the refund to appear in your account based on your bank's processing times.
          </p>

          <h2>3. Subscription Cancellations</h2>
          <p>
            You may choose to cancel your subscription at any time. However, canceling your subscription will not result in a refund for any portion of the subscription term already purchased. You will continue to have access to the services until the end of your subscription term.
          </p>

          <h2>4. Billing Issues</h2>
          <p>
            If you encounter any issues with your billing or believe you have been incorrectly charged, please contact us at <strong>support@phishroko.com</strong>. We will review your case and work to resolve any billing discrepancies. However, please be aware that this will not result in a refund for a subscription that has already been processed.
          </p>

          <h2>5. Changes to This Policy</h2>
          <p>
            We reserve the right to update or modify this Return and Refund Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website. We encourage you to review this page periodically to stay informed about our refund practices.
          </p>

          <h2>6. Contact Us</h2>
          <p>
            If you have any questions or concerns regarding this policy, please feel free to contact us:
          </p>
          <p><strong>Email:</strong> support@phishroko.com</p>
          <p><strong>Company Name:</strong> Capank Associates Private Limited</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ReturnRefundPolicy;
