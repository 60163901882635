import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import OTPModal from './OTPModal';       // Import the OTPModal component

const countries = {
  USA: { dialCode: '+1', maxDigits: 10 },
  Canada: { dialCode: '+1', maxDigits: 10 },
  India: { dialCode: '+91', maxDigits: 10 },
  Australia: { dialCode: '+61', maxDigits: 10 },
  UK: { dialCode: '+44', maxDigits: 11 },
  'New Zealand': { dialCode: '+64', maxDigits: 10 }
};
// Add this mapping object for country codes
const countryCodes = {
  USA: 'USA',
  Canada: 'CAN',
  India: 'INDIA',
  Australia: 'AUS',
  UK: 'UK',
  'New Zealand': 'NZ'
};

const SignupModal = ({ show, handleClose, switchToLogin,onSignupSuccess }) => {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [dialCode, setDialCode] = useState('');
  const [maxDigits, setMaxDigits] = useState(10);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [otpModalShow, setOtpModalShow] = useState(false); // State to control OTP Modal
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const clearForm = () => {
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setCountry('');
    setContactNumber('');
    setDialCode('');
    setMaxDigits(10);
    setPasswordVisible(false);
    setConfirmPasswordVisible(false);
    setErrorMessage('');
  };
  const handleSignup = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true); // Start loading animation

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      setLoading(false); // Stop loading animation
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      setLoading(false); // Stop loading animation
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Account/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: firstName,        // Blank username
          email: email,
          password: password,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          contact: contactNumber,
          address: '',         // Blank address
          country: countryCodes[country] || country // Use country code
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setVerificationCode(data.verificationCode); // Save the verification code
        setOtpModalShow(true); // Show the OTP modal
      } else if (response.status === 400) {
        const errorData = await response.text();
        setErrorMessage(errorData || 'Signup failed. Please try again.');
        // setErrorModalShow(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Signup error:', error);
      setErrorMessage('An error occurred during signup.');
    }finally {
        setLoading(false); // Stop loading animation
      }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setDialCode(countries[selectedCountry]?.dialCode || '');
    setMaxDigits(countries[selectedCountry]?.maxDigits || 10);
    setContactNumber('');
  };

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);

  const handleNameChange = (setter) => (event) => {
    const value = event.target.value;
    if (!/^\d+$/.test(value)) { // Prevent numbers
      setter(value);
    }
  };

  const handleContactNumberChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= maxDigits) {
      setContactNumber(value);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
            clearForm();
            handleClose();
          }}
        centered
        backdrop="static"
        keyboard={false}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Modal.Body style={{ position: 'relative' }}>
          <Button
            variant="close"
            onClick={() => {
                clearForm();
                handleClose();
              }}
            style={{ position: 'absolute', top: '10px', right: '10px' }}
          >
            {/* &times; */}
          </Button>
          <div className="text-center mb-4">
            <img
              src="../assets/images/phishroko_cropped.png"
              alt="Logo"
              style={{ width: '100px', height: 'auto' }}
            />
          </div>
          <Form onSubmit={handleSignup}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={handleNameChange(setFirstName)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Middle Name"
                value={middleName}
                onChange={handleNameChange(setMiddleName)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={handleNameChange(setLastName)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Control
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ paddingRight: '40px' }}
              />
              <Button
                variant="link"
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </Button>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Control
                type={confirmPasswordVisible ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={{ paddingRight: '40px' }}
              />
              <Button
                variant="link"
                onClick={toggleConfirmPasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                {confirmPasswordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </Button>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                as="select"
                value={country}
                onChange={handleCountryChange}
                required
              >
                <option value="">Select Country</option>
                {Object.keys(countries).map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <InputGroup>
                <InputGroup.Text>{dialCode}</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Contact Number"
                  value={contactNumber}
                  onChange={handleContactNumberChange}
                  required
                />
              </InputGroup>
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <div className="d-flex justify-content-between mb-3">
              <Button 
  type="submit" 
  variant="primary" 
  style={{ margin: '0 auto', display: 'block' }}
  disabled={loading} // Disable button while loading
>
  {loading ? (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : (
    'Sign Up'
  )}
</Button>
    </div> 
    <div className="text-center">
            <Button variant="link" onClick={switchToLogin}>
              Already have an account? Login
            </Button>
          </div></Form> </Modal.Body> </Modal>

  {/* OTP Modal */}
  <OTPModal
  show={otpModalShow}
  handleClose={() => setOtpModalShow(false)}
  verificationCode={verificationCode}
  handleCloseSignup={handleClose} // Pass the handleClose to OTPModal
  email={email} // Pass email
  password={password} // Pass password
  onLoginSuccess={onSignupSuccess} // Pass the onLoginSuccess callback
/>
</>
); };

export default SignupModal;
