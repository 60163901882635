import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import USAStates from '../../assets/USA_states.json'; // Import your JSON file

const ProfileModal = ({ show, onHide, userDetails }) => {
  const [editing, setEditing] = useState(false);
  const [houseNumber, setHouseNumber] = useState('');
  const [street, setStreet] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [contact, setContact] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (editing && zipCode && userDetails?.country === 'USA') {
      updateStateCityByZip(zipCode);
    }
  }, [editing, zipCode]);

  const updateStateCityByZip = (zip) => {
    for (const stateCode in USAStates) {
      const stateData = USAStates[stateCode];
      for (const cityName in stateData.cities) {
        if (stateData.cities[cityName].includes(parseInt(zip))) {
          setState(stateCode);
          setCity(cityName);
          return;
        }
      }
    }
    setError('Invalid Zip Code');
  };

  const handleEditClick = () => {
    const addressParts = userDetails?.address?.split(', ') || [];
    setHouseNumber(addressParts[0] || '');
    setStreet(addressParts[1] || '');

    const cityStateZip = addressParts[2]?.split(' - ') || [];
    if (cityStateZip.length > 1) {
      setZipCode(cityStateZip[1] || '');
    }

    setContact(userDetails.contact || '');
    setEditing(true);
  };

  const handleSave = async () => {
    if (!houseNumber || !street || !city || !state || !zipCode || !contact) {
      setError('Please fill in all fields.');
      return;
    }

    const address = `${houseNumber}, ${street}, ${city}, ${state} - ${zipCode}`;

    const payload = {
      id: userDetails.id,
      firstName: userDetails.firstName,
      middleName: userDetails.middleName,
      lastName: userDetails.lastName,
      address,
      contact,
      country: userDetails.country,
      role: userDetails.role,
    };
    const token = localStorage.getItem('token');
    
    setLoading(true); // Start loading
    setError(''); // Clear previous error
    setSuccess(''); // Clear previous success message

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/User/update-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      if (response.ok) {
        setSuccess(responseData.message || 'Profile updated successfully.');
        setShowSuccessModal(true); // Show success modal
      } else {
        setError(responseData.message || 'Failed to update profile.');
      }
    } catch (error) {
      setError('An error occurred while updating the profile.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleModalClose = () => {
    setEditing(false);
    setError('');
    setSuccess('');
    setHouseNumber('');
    setStreet('');
    setState('');
    setCity('');
    setZipCode('');
    setContact('');
    onHide();
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    handleModalClose();
  };

  const getInitials = (firstName, lastName) => {
    if (!firstName || !lastName) return '';
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  };

  const iconStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#000',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px'
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ backgroundColor: '#34ebc0' }} closeButton>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {userDetails && (
              <div style={iconStyle}>
                {getInitials(userDetails.firstName, userDetails.lastName)}
              </div>
            )}
            <Modal.Title>
              {userDetails
                ? `${userDetails.firstName} ${userDetails.middleName} ${userDetails.lastName}`
                : 'User Profile'}
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          {userDetails ? (
            <div>
              <p><strong>Email:</strong> {userDetails.email}</p>
              <p><strong>Username:</strong> {userDetails.username}</p>

              <Form>
                <Form.Group>
                  <Form.Label><strong>Address</strong></Form.Label>
                  {editing ? (
                    <Row>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          placeholder="House Number"
                          value={houseNumber}
                          onChange={(e) => setHouseNumber(e.target.value)}
                        />
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          placeholder="Street"
                          value={street}
                          onChange={(e) => setStreet(e.target.value)}
                        />
                      </Col>
                      {userDetails.country === 'USA' && (
                        <>
                          <Col md={4} className="mt-3">
                            <Form.Control
                              as="select"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                            >
                              <option value="">Select State</option>
                              {Object.keys(USAStates).map((stateCode) => (
                                <option key={stateCode} value={stateCode}>
                                  {USAStates[stateCode].name}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          <Col md={4} className="mt-3">
                            <Form.Control
                              as="select"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              disabled={!state}
                            >
                              <option value="">Select City</option>
                              {state &&
                                Object.keys(USAStates[state].cities).map((cityName) => (
                                  <option key={cityName} value={cityName}>
                                    {cityName}
                                  </option>
                                ))}
                            </Form.Control>
                          </Col>
                        </>
                      )}
                      <Col md={4} className="mt-3">
                        <Form.Control
                          type="text"
                          placeholder="Zip Code"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <p>
                      {userDetails.address || 'N/A'}
                    </p>
                  )}
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label><strong>Contact</strong></Form.Label>
                  {editing ? (
                    <Form.Control
                      type="text"
                      placeholder="Enter contact number"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  ) : (
                    <p>{userDetails.contact}</p>
                  )}
                </Form.Group>
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </Form>

              <p><strong>Country:</strong> {userDetails.country}</p>
              <p>
                <strong>Subscription:</strong> 
                {userDetails.subscriptionType === 0 
                  ? 'Not subscribed' 
                  : `Subscribed (Expires on: ${new Date(userDetails.subscription.expiresIn).toLocaleDateString()})`}
              </p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          {editing ? (
            <>
              <Button 
                variant="primary" 
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="visually-hidden">Saving...</span>
                  </>
                ) : (
                  'Save'
                )}
              </Button>
              <Button variant="secondary" onClick={() => setEditing(false)}>
                Cancel
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={handleEditClick}>
              Edit
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{success}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileModal;
