import React from 'react';
import { Container } from 'react-bootstrap';
import backgroundImage from '../../assets/images/subtle-prism1.png';  
export default function CommunityPage() {
  return (
    
    <div
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      padding: '1rem 0',
      color: '#fff',
      top: '60px',
      position: 'fixed', // Fixes the entire page
      width: '80%',
      height: '100vh',
      overflowY: 'auto',
    }}>>

    
    <Container style={{ width: '80%', marginTop: '100px', textAlign: 'center' }}>
      <h2 className="mb-4">Community Page</h2>
      <p style={{ fontSize: '1.5rem', color: '#555' }}>This page is currently under development. Stay tuned for updates!</p>
    </Container>
    </div>
  );
}
