import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form, ProgressBar, Alert } from 'react-bootstrap';

const CreateTicketModal = ({
  show,
  onHide,
  isLoading,
  setIsLoading,
  fetchTickets // Function to refetch tickets
}) => {
  const [ticketDetails, setTicketDetails] = useState({ title: '', description: '' });
  const [files, setFiles] = useState([]);
  const [attachmentFields, setAttachmentFields] = useState([{ id: 1 }]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [responseMessage, setResponseMessage] = useState(''); // Store response message
  const [showResponseModal, setShowResponseModal] = useState(false); // Control the response modal
  const uploadAbortController = useRef(new AbortController());

  useEffect(() => {
    if (show) {
      // Reset form fields when the modal opens
      setTicketDetails({ title: '', description: '' });
      setFiles([]); // Clear selected files
      setAttachmentFields([{ id: 1 }]); // Reset attachment fields
      setUploadProgress({}); // Reset upload progress
      setResponseMessage(''); // Clear any response message
    }
  }, [show]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTicketDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (event, fieldId) => {
    const file = event.target.files[0];
    if (file && file.size <= 524288000) { // 500 MB limit
      const updatedFiles = files.filter(f => f.id !== fieldId);
      setFiles([...updatedFiles, { id: fieldId, file }]);
    } else {
      setResponseMessage("File size exceeds the limit of 500 MB.");
      setShowResponseModal(true);
    }
  };

  const handleAddAttachment = () => {
    if (attachmentFields.length < 5) {
      setAttachmentFields((prevFields) => [
        ...prevFields,
        { id: prevFields.length + 1 },
      ]);
    } else {
      setResponseMessage("You can only add up to 5 attachments.");
      setShowResponseModal(true);
    }
  };

  const uploadFile = (file, id) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
      const formData = new FormData();
      formData.append("file", file);

      xhr.open(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/api/files/upload?userId=${userId}`,
        true
      );
      const controller = uploadAbortController.current;
      xhr.signal = controller.signal;

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          setUploadProgress(prev => ({ ...prev, [id]: percentComplete }));
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          resolve(response.fileUrl);
        } else {
          reject("Failed to upload file");
        }
      };

      xhr.onerror = () => reject("Error during file upload");

      xhr.send(formData);
    });
  };

  const handleCreateTicketSubmit = async () => {
    setIsLoading(true);
    setResponseMessage(''); // Clear any previous response message
  
    try {
      const fileUrls = [];
      for (const { file, id } of files) {
        if (file) {
          const fileUrl = await uploadFile(file, id);
          fileUrls.push(fileUrl);
        }
      }
      const token = localStorage.getItem("token");
      const attachments = fileUrls.join(';'); // Concatenate URLs with semicolon
      const ticketData = { 
        ...ticketDetails, 
        attachment: attachments // Use "attachment" field name in payload
      };
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/Ticket/create-ticket`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(ticketData),
      });
  
      if (response.status === 400) {
        const errorText = await response.text();
        throw new Error(errorText); // Throw the error with response text
      }
  
      if (!response.ok) {
        throw new Error('Ticket creation failed');
      }
  
      setResponseMessage('Ticket created successfully!'); // Set success message
      await fetchTickets(); // Fetch updated tickets
    } catch (error) {
      console.error("Ticket creation failed:", error);
      setResponseMessage(error.message || "Failed to create ticket. Please try again."); // Set failure message
    } finally {
      setIsLoading(false);
      setUploadProgress({}); // Reset upload progress
      setFiles([]); // Clear the selected files
      setAttachmentFields([{ id: 1 }]); // Reset attachment fields
      setShowResponseModal(true); // Show response modal
    }
  };
  

  const handleClose = () => {
    setTicketDetails({ title: '', description: '' }); // Reset title and description
    setFiles([]); // Clear selected files
    setAttachmentFields([{ id: 1 }]); // Reset attachment fields
    setUploadProgress({}); // Reset upload progress
    setResponseMessage(''); // Clear any response message
    setShowResponseModal(false); // Close response modal
    onHide(); // Close the create ticket modal
  };

  return (
    <>
      <Modal 
        show={show} 
        onHide={handleClose} 
        centered
        backdrop="static" // Prevent closing on clicking outside
        keyboard={false} // Prevent closing on pressing escape key
        
      >
        <Modal.Header closeButton style={{ backgroundColor: '#4f98e8' }}>
          <Modal.Title>Create Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={ticketDetails.title}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={ticketDetails.description}
                onChange={handleChange}
              />
            </Form.Group>

            {attachmentFields.map(({ id }) => (
              <Form.Group key={id} controlId={`formFile${id}`}>
                <Form.Label>Attachment {id}</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(e, id)}
                />
                {uploadProgress[id] !== undefined && (
                  <ProgressBar
                    animated
                    now={uploadProgress[id]}
                    label={`${uploadProgress[id]}%`}
                    style={{ marginTop: '10px' }}
                  />
                )}
              </Form.Group>
            ))}

            {attachmentFields.length < 5 && (
              <Button
                variant="link"
                onClick={handleAddAttachment}
                style={{ display: 'block', margin: '10px 0' }}
              >
                Add more attachments
              </Button>
            )}

            <Button
              variant="primary"
              onClick={handleCreateTicketSubmit}
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'Create Ticket'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Response Modal */}
      <Modal 
        show={showResponseModal} 
        onHide={handleClose} 
        centered
        size="sm"
      >
        <Modal.Header style={{ backgroundColor: '#4f98e8' }} closeButton>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={responseMessage.includes('successfully') ? "success" : "danger"}>
            {responseMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateTicketModal;
