import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 style={{ textAlign: 'center' }} className="my-4">Privacy Policy</h1>
          <p><strong>Last Updated: 9<span style={{ fontSize: '0.75rem', verticalAlign: 'super' }}>th</span> September, 2024</strong></p>

          <h2>Introduction</h2>
          <p>
            Phishroko.com, a division of Capank Associates Private Limited (“we,” “us,” or “our”), is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you visit our website or use our services, in compliance with applicable privacy laws in the United States (including California) and Canada.
          </p>
          <p>By accessing our website, you agree to this Privacy Policy.</p>

          <h2>1. Information We Collect</h2>
          <p>We may collect the following personal information:</p>
          <ul>
            <li><strong>Contact Information:</strong> Name, email address, phone number.</li>
            <li><strong>Payment Information:</strong> Payment details necessary for processing transactions (e.g., credit/debit card information, billing address).</li>
            <li><strong>Authentication Information:</strong> Data for verifying your identity and maintaining your account.</li>
            <li><strong>Technical Information:</strong> IP address, browser type, operating system, and usage details to enhance your website experience.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use the data we collect for the following purposes:</p>
          <ul>
            <li><strong>To Provide Services:</strong> Authenticate users, provide secure access, and process transactions.</li>
            <li><strong>To Improve Services:</strong> Analyze user activity to improve our website and services.</li>
            <li><strong>Legal Compliance:</strong> Ensure compliance with legal obligations and respond to legal requests.</li>
          </ul>

          <h2>3. Your Rights Under U.S. and Canadian Law</h2>
          <h3>Your Rights Under the California Consumer Privacy Act (CCPA)</h3>
          <p>If you are a California resident, you have the following rights under the CCPA:</p>
          <ul>
            <li><strong>Right to Know:</strong> You may request details about the personal data we collect, use, and share.</li>
            <li><strong>Right to Delete:</strong> You can request that we delete your personal data, subject to certain exceptions.</li>
            <li><strong>Right to Opt-Out:</strong> You may opt-out of the sale of your personal data. However, we do not sell personal data.</li>
            <li><strong>Right to Non-Discrimination:</strong> We will not discriminate against you for exercising any of your rights under the CCPA.</li>
          </ul>

          <h3>Your Rights Under the Personal Information Protection and Electronic Documents Act (PIPEDA)</h3>
          <p>If you are in Canada, you have the following rights under PIPEDA:</p>
          <ul>
            <li><strong>Right to Access:</strong> You have the right to request access to the personal information we hold about you.</li>
            <li><strong>Right to Correct:</strong> You may request corrections to any inaccurate or incomplete personal data.</li>
            <li><strong>Right to Withdraw Consent:</strong> You may withdraw consent for the use of your data at any time, subject to legal or contractual restrictions.</li>
            <li><strong>Right to Challenge Compliance:</strong> You may challenge our compliance with PIPEDA by contacting us directly.</li>
          </ul>

          <h2>4. Data Security</h2>
          <p>
            We take appropriate technical and organizational security measures to protect your personal data from unauthorized access, misuse, or disclosure. However, no method of transmission over the Internet is entirely secure, and we cannot guarantee absolute security.
          </p>

          <h2>5. Data Retention</h2>
          <p>We retain your personal data for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce agreements.</p>

          <h2>6. Cookies</h2>
          <p>We do not use cookies for tracking purposes. However, third-party services integrated into our site may use cookies, and you are advised to check their privacy policies.</p>

          <h2>7. Sharing Your Information</h2>
          <p>We do not sell, trade, or share your personal information with third parties for marketing purposes. However, we may disclose your data:</p>
          <ul>
            <li>To comply with legal obligations.</li>
            <li>To service providers who assist in our business operations, subject to confidentiality agreements.</li>
          </ul>

          <h2>8. Children's Privacy</h2>
          <p>Our website is not intended for individuals under the age of 16. We do not knowingly collect personal data from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will take steps to delete that information.</p>

          <h2>9. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices, legal requirements, or operational needs. We encourage you to review this policy periodically.</p>

          <h2>10. Contact Us</h2>
          <p>If you have any questions, concerns, or requests regarding your personal information or this Privacy Policy, please contact us:</p>
          <p><strong>Email:</strong> support@phishroko.com</p>
          <p><strong>Company Name:</strong> Capank Associates Private Limited</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
