import React from "react";
import { Button } from "react-bootstrap";
import LoginModal from "../authPage/LoginModal";
import SignupModal from "../authPage/SignupModal";

const NotLoggedInNavbar = ({ handleOpenLogin, handleOpenSignup, showModal, handleClose, handleLoginSuccess }) => (
  <div className="navbar-buttons">
    <Button
      variant="primary"
      onClick={handleOpenLogin}
      className="me-2"
    >
      Login
    </Button>
    <Button
      variant="primary"
      onClick={handleOpenSignup}
      className="me-2"
    >
      Signup
    </Button>
    <LoginModal
      show={showModal === "login"}
      handleClose={handleClose}
      switchToSignup={() => handleOpenSignup()}
      onLoginSuccess={handleLoginSuccess}
    />
    <SignupModal
      show={showModal === "signup"}
      handleClose={handleClose}
      switchToLogin={() => handleOpenLogin()}
      onSignupSuccess={handleLoginSuccess}
    />
  </div>
);

export default NotLoggedInNavbar;
