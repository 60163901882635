import React, { useState, useRef, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import 'bootstrap/dist/css/bootstrap.min.css';

const Chat = () => {
  const [sessionId, setSessionId] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isSessionActive, setIsSessionActive] = useState(false);
  const connectionRef = useRef(null);
  const chatWindowRef = useRef(null);
  const inputRef = useRef(null);

  const userId = JSON.parse(localStorage.getItem('userData'))?.userId;

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && isSessionActive) {
        event.preventDefault();
        handleSendMessage();
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isSessionActive, message]);

  const handleStartSession = async () => {
    if (!connectionRef.current) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl('https://phishroko-api-dev.azurewebsites.net/chathub')
        .build();

      try {
        await newConnection.start();
        console.log('Client connected');
        connectionRef.current = newConnection;

        const newSessionId = await newConnection.invoke('StartNewSession', userId);
        if (newSessionId) {
          setSessionId(newSessionId);
          setIsSessionActive(true);
          console.log('Joined session successfully with sessionId:', newSessionId);

          newConnection.on('ReceiveMessage', (user, msg) => {
            setMessages((prevMessages) => [
              ...prevMessages, 
              { user, msg, time: new Date().toLocaleTimeString() }
            ]);
          });
        }
      } catch (err) {
        console.error('Connection or session start error:', err);
      }
    }
  };

  const handleEndSession = async () => {
    if (connectionRef.current) {
      try {
        await connectionRef.current.stop();
        console.log('Connection stopped, session ended.');
        connectionRef.current = null;
        setIsSessionActive(false);
        setSessionId(null);
      } catch (err) {
        console.error('Error ending the session:', err);
      }
    }
  };

  const handleSendMessage = () => {
    if (sessionId && connectionRef.current && message.trim() !== '') {
      connectionRef.current.invoke('SendMessage', Number(sessionId), 2, message)
        .then(() => {
          setMessages((prevMessages) => [
            ...prevMessages, 
            { user: 'You', msg: message, time: new Date().toLocaleTimeString() }
          ]);
          setMessage('');
        })
        .catch((err) => console.error('Send message error:', err));
    } else if (!message.trim()) {
      console.error('Message cannot be empty');
    } else {
      console.error('Session not started yet');
    }
  };

  return (
    <div className="container-fluid d-flex flex-column" style={{ marginTop: '60px', paddingRight: '15px', position: 'relative', height: '85vh' }}>
      <div className="bg-success text-white p-3 text-center font-weight-bold">
        <h2>Client Chat</h2>
        {!isSessionActive ? (
          <button onClick={handleStartSession} className="btn btn-primary mt-2">Start Chat Session</button>
        ) : (
          <button onClick={handleEndSession} className="btn btn-danger mt-2">End Chat Session</button>
        )}
      </div>
      
      <div
        className="flex-grow-1 bg-light p-3 overflow-auto d-flex flex-column"
        style={{ maxHeight: 'calc(100% - 80px)' }}
        ref={chatWindowRef}
      >
        {messages.length > 0 ? (
          messages.map((msg, index) => (
            <div key={index} className={`mb-2 p-2 rounded ${msg.user === 'You' ? 'bg-success text-white align-self-end' : 'bg-white align-self-start'}`}>
              <span>{msg.msg}</span>
              <div className="text-muted small mt-1">{msg.time}</div>
            </div>
          ))
        ) : (
          <p className="text-center text-muted">No messages yet...</p>
        )}
      </div>

      {isSessionActive && (
        <div className="position-absolute bottom-0 start-0 end-0 d-flex" style={{ paddingRight: '15px', backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6' }}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a message"
            className="form-control me-2"
            style={{ borderRadius: '20px', marginRight: '10px' }}
            ref={inputRef}
          />
          <button onClick={handleSendMessage} className="btn btn-primary" style={{ borderRadius: '20px' }}>Send</button>
        </div>
      )}
    </div>
  );
};

export default Chat;
