import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import LoginModal from '../components/authPage/LoginModal'; // Import LoginModal
import logo from '../assets/images/phishroko_with_name.png'; // Replace with the path to your logo

const LoginSignupModal = ({ showModal, handleClose, handleLogin, handleSignup, onLoginSuccess }) => (
  <Modal
    show={showModal}
    onHide={handleClose}
    centered
    backdrop="static"
    keyboard={false}
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  size='sm'>
    <Modal.Body style={{ position: 'relative' }}>
      <Button
        variant="close"
        onClick={handleClose}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        {/* &times; */}
      </Button>
      <div className="text-center mb-4">
        <img
          src={logo}
          alt="Logo"
          style={{ width: '200px', height: 'auto' }}
        />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="text-center mb-3">
          <div className="mb-2">Login/Signup</div>
          <Button
            variant="danger" // Red color for Login button
            style={{ width: '150px', height: '50px' }}
            onClick={() => {
              handleLogin(); // Trigger the login modal
              handleClose(); // Close the current modal
            }}
          >
            Login
          </Button>
        </div>
        <div className="text-center">
          {/* <div className="mb-2">Signup</div> */}
          <Button
            variant="primary" // Blue color for Signup button
            style={{ width: '150px', height: '50px' }}
            onClick={() => {
              handleSignup(); // Trigger the signup modal
              handleClose(); // Close the current modal
            }}
          >
            Signup
          </Button>
        </div>
      </div>

      {/* Login Modal */}
      <LoginModal
        show={showModal === "login"}
        handleClose={handleClose}
        switchToSignup={() => handleSignup()}
        onLoginSuccess={onLoginSuccess} // Pass onLoginSuccess to LoginModal
      />
    </Modal.Body>
  </Modal>
);

export default LoginSignupModal;
