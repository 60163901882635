import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const OTPModal = ({ show, handleClose, verificationCode, handleCloseSignup, email, password,onLoginSuccess }) => {
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // For OTP submission
  const [loginLoading, setLoginLoading] = useState(false); // For login process
  const navigate = useNavigate();

  const clearForm = () => {
    setOtp('');
    setMessage('');
    setLoading(false);
    setLoginLoading(false);
  };

  const handleLogin = async () => {
    setLoginLoading(true); // Start login spinner
    try {
      const loginResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Account/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (loginResponse.ok) {
        debugger
        const data = await loginResponse.json();
        localStorage.setItem('token', data.token);
        localStorage.setItem('userData', JSON.stringify(data));
        window.location.reload(true);
        onLoginSuccess();
        navigate('/dashboard/complaints');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Login error:', error);
      navigate('/');
    } finally {
      setLoginLoading(false); // Stop login spinner
      handleClose(); // Close OTP modal
      handleCloseSignup(); // Close Signup modal
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setLoading(true); // Start OTP submission spinner

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Account/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          validationCode: otp,
          authCode: verificationCode,
        }),
      });

      const data = await response.json();
      if (data === true) {
        setMessage('OTP verified successfully.');
        await handleLogin(); // Proceed to login after successful OTP verification
      } else {
        setMessage('OTP verification failed. Please try again.');
      }
    } catch (error) {
      console.error('OTP verification error:', error);
      setMessage('An error occurred during OTP verification.');
    } finally {
      setLoading(false); // Stop OTP submission spinner
    }
  };

  const handleOtpChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setOtp(value);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        clearForm();
        handleClose();
        handleCloseSignup(); // Close Signup modal when OTP modal is closed
      }}
      centered
      backdrop="static"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">Enter OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loginLoading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Logging you in...</p>
          </div>
        ) : (
          <>
            <p>OTP has been sent to your registered email.</p>
            <Form onSubmit={handleOtpSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter 6-digit OTP"
                  value={otp}
                  onChange={handleOtpChange}
                  maxLength={6}
                  required
                />
              </Form.Group>
              {message && <p className="text-danger text-center">{message}</p>}
              <div className="d-flex justify-content-center">
                <Button
                  type="submit"
                  variant="primary"
                  className="mt-2"
                  disabled={loading} // Disable button while loading OTP submission
                >
                  {loading ? (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    'Submit OTP'
                  )}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OTPModal;
