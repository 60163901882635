import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import ProfileModal from './ProfileModal'; // Adjust the path as necessary
import ChangePasswordModal from './ChangePasswordModal';
import profileBackground from '../../assets/images/profile.png';

const LoggedInNavbar = ({ userInitials, fullName, handleLogout }) => {
  const [showLogout, setShowLogout] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/User/get-user-details?id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUserDetails(data);
        } else {
          console.error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleProfileClick = () => {
    setShowProfileModal(true);
  };

  const handleOpenChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };

  const handleModalClose = () => {
    setShowProfileModal(false);
    setShowChangePasswordModal(false);
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{ position: "relative" }}
      onMouseEnter={() => {
        setShowLogout(true);
        setShowMenu(true);
      }}
      onMouseLeave={() => {
        setShowLogout(false);
        setShowMenu(false);
      }}
    >
      <span className="me-2 text-black">Welcome, {fullName}</span>
      <div
        className="user-icon"
        onClick={() => setShowLogout(!showLogout)}
      >
        {userInitials || "?"}
      </div>
      <div
        className="user-menu"
        style={{
          position: "absolute",
          right: 0,
          top: "100%",
          marginTop: "5px",
          opacity: showMenu ? 1 : 0,
          visibility: showMenu ? "visible" : "hidden",
          transition: "opacity 0.3s ease, visibility 0.3s ease",
          backgroundImage: `url(${profileBackground})`,
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Button
          variant="link"
          style={{
            color: "#000",
            fontSize: "1rem",
            padding: "0",
            textDecoration: "none",
          }}
          onClick={handleProfileClick}
        >
          Profile
        </Button>
        <Button
          variant="link"
          style={{
            color: "#000",
            fontSize: "1rem",
            padding: "0",
            textDecoration: "none",
          }}
          onClick={handleOpenChangePasswordModal}
        >
          Change Password
        </Button>
        <Button
          variant="danger"
          className="d-block text-black"
          style={{ marginTop: "5px" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>

      {/* Profile Modal */}
      <ProfileModal
        show={showProfileModal}
        onHide={handleModalClose}
        userDetails={userDetails}
      />

      {/* Change Password Modal */}
      <ChangePasswordModal
        show={showChangePasswordModal}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default LoggedInNavbar;
